
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    bgHexColor: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const badgeBorderColor = computed(() =>
      props.borderColor ? props.borderColor : props.bgHexColor
    );

    const badgeInlineStyle = computed(() => {
      return `
        background-color: ${props.bgHexColor};
        color: ${props.textColor};
        border-color: ${badgeBorderColor.value}
      `;
    });

    return {
      badgeInlineStyle,
    };
  },
});
