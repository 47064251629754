import { computed, ComputedRef } from 'vue';
import { Consumer } from '@/modules/consumer/types';
import { useStore } from 'vuex';

type User = { email: string, jwt: string, uid: string }

export const useAuth = (): {
  headers: ComputedRef<unknown>,
  user: ComputedRef<{ jwt: string, uid: string | null, email: string | null }>,
  consumer: ComputedRef<Consumer>,
} => {
  const store = useStore()
  store.dispatch('authModule/refreshHeaders', null, { root: true,});

  const headers = computed(() => store.getters['authModule/authHeaders'])
  const user = computed<User>(() => store.state.authModule.user)
  const consumer = computed(() => (store.getters['consumerModule/consumer']))

  return {
    headers,
    user,
    consumer,
  };
};
